@tailwind utilities;
@layer utilities {
  .grid-internal-border {
    position: relative;
    --gap-x: 40px;
    --gap-y: 40px;
    --line-x-offset: calc(var(--gap-x) / 2);
    --line-y-offset: calc(var(--gap-y) / 2);
    --line-thickness: 1px;
    --line-color: #E0E4E9;
  }

  .grid-internal-border::before {
    content: "";
    position: absolute;
    background-color: var(--line-color);
    z-index: 1;
    inline-size: var(--line-thickness);
    block-size: 100%;
    inset-block-start: 0;
    inset-inline-start: calc(var(--line-y-offset) * -1);
  }

  .grid-internal-border::after {
    content: "";
    position: absolute;
    background-color: var(--line-color);
    z-index: 1;
    inline-size: 100vw;
    block-size: var(--line-thickness);
    inset-inline-start: 0;
    inset-block-start: calc(var(--line-x-offset) * -1);
  }
}
